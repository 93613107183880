export const environment = {
  production: true,

  baseUrl: "https://api.nurture.farm/afs/",
  unimartBaseUrl: "https://api.nurture.farm/unimart/",
  farmerAppBaseURL: "https://api.nurture.farm/afs-farmer-app/",
  rsBaseUrl: "https://api.nurture.farm/afs-rs/",
  s3_bucket_client: "afs-prod-client",
  s3_bucket: "afs-prod-server",
  farmerAppClientKey: "mdm-server",
  farmerAppClientSecret: "AAeIDiHZUFFSrnRXn4L38SqmruOoa6taSolv8zS1",
  mapsPortalUrl: "https://maps.nurture.farm/",
  retailDbAthena: "uplecommerce",
  farmDbAthena: "afsdb",
  partnerDbAthena: "pms",
  firebaseConfig: {
    apiKey: "AIzaSyB4oyD8IdsiwVDvZq6ZQxBAv5PSDG9N4F0",
    authDomain: "farm-mdm.firebaseapp.com",
    projectId: "farm-mdm",
    storageBucket: "farm-mdm.appspot.com",
    messagingSenderId: "231936911250",
    appId: "1:231936911250:web:247e8090cdddfeda22834b",
    measurementId: "G-YPDFG1KJHK",
  },
  CampaighnTemplateClientId: 'template-management-service',
  CampaighnTemplateClientSecretId: 'fLYuJzqwPDrfBywrGPbS',
  aws_cms: {
    S3_BUCKET: "content-management-service-prod",
    S3_REGION: "ap-south-1"
  },
  mapApiKey: "AIzaSyAvD_cQBcfxVkcXjakTCl64gnmLdsq7WbE",
  aws_credentials: {
    AWS_ACCESS_KEY: "AKIAJPXBGOTTSB3X5SZQ",
    AWS_SECRET_KEY: "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",
    AWS_REGION: "ap-south-1"
  },
};
